module.exports = {
  "text-black": "hsla(203, 20%, 10%, 1)",
  "text-gray": "hsla(203, 20%, 30%, 1)",
  "text-light-gray": "hsl(203, 10%, 43%)",
  "text-green": "hsla(152, 60%, 37%, 1)",
  "text-error": "#E53E3E",

  "background-dark": "hsla(203, 59%, 57%, 1)",
  "background-dark-secondary": "hsla(152, 26%, 55%, 1)",
  "background-light": "hsla(150, 100%, 98%, 1)"
}
