import React from "react"
import { css } from "@emotion/core"
import { ChevronDown } from "react-feather"

export default props => {
  return (
    <div
      css={css`
        vertical-align: -20%;
        display: inline-block;
        margin-left: .3rem;

        * {
          stroke-width: 2px;
        }

        svg {
          height: 1.1rem;
          width: auto;
        }

        ${props.style}
      `}
    >
      <ChevronDown />
    </div>
  )
}