import React from "react"
import { Helmet } from "react-helmet"

import basics from "../cms/basics"
import home from '../cms/home'

export default props => {
  const siteName = basics.site_name
  const title = props.title ? props.title + " — " + siteName : siteName

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="shortcut icon" href="https://emojicdn.elk.sh/🌱" />

      <link href="https://rsms.me/inter/inter.css" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css?family=Jaldi:700" rel="stylesheet" />

      <meta property="og:image" content={'https://onecello.org/' + home.image} />
    </Helmet>
  )
}
