import React from "react"
import { css } from "@emotion/core"

import NavLink from "./NavLink"
import { ChevronDown } from "react-feather"

export default props => {
  const pages = props.pages
  const render = []
  for(const category in pages) {
    if(pages[category].length === 1) {
      render.push(
        <div
          key={category}
          css={css`
            max-width: max-content;
          `}
        >
          <NavLink to={pages[category][0].to} text={category} />
        </div>
      )
    } else {
      render.push(
        <div
          key={category}
          css={css`
            max-width: max-content;
            cursor: pointer;
            position: relative;

            * :not(style) {
              display: inline-block;
            }

            :hover {
              .dropdown {
                opacity: 1;
                transform: translateY(0);
                pointer-events: all;
              }
            }
          `}
          className="flex items-center"
        >
          <p>{category}</p>
          <ChevronDown className="h-4 mb-1 opacity-80" />

          <div
            className="dropdown"
            css={css`
              background-color: hsla(0, 0%, 100%, 1);
              box-shadow: 0 2px 12px hsla(0, 0%, 0%, .2);
              padding: .8rem 1rem;
              position: absolute;
              top: 1.5rem;
              left: 0;
              z-index: 1;
              border-radius: .3rem;

              opacity: 0;
              transition: .2s;
              transform: translateY(-.5rem);
              pointer-events: none;
            `}
          >
            {pages[category].map(page => (
              <NavLink
                to={page.to}
                style={css`
                  display: block !important;
                  margin-bottom: .5rem;

                  :last-of-type {
                    margin-bottom: 0;
                  }
                `}
                text={page.name}
                dark={true}
                key={page.name}
              />
            ))}
          </div>
        </div>
      )
    }
  }

  return (
    <nav
      css={css`
        * {
          color: white;
          font-weight: 500;
          text-decoration: none !important;
        }
      `}
    >
      <div
        css={css`
          margin-right: 1rem;
          background-color: var(--background-dark);
          padding: 2rem;
          margin-right: 0;
          text-align: center;

          > div {
            display: inline-block;
            margin-right: 1rem;
          }
        `}
      >
        {render}
      </div>
    </nav>
  )
}
