import React from "react"
import { css, Global } from "@emotion/core"

const colors = require("../util/colors.js");

export default () => {
  let cssVars = ``;

  for(const color in colors) {
    cssVars += `--${color}: ${colors[color]};`
  }

  return (
    <Global
      styles={css`
        :root {
          ${cssVars}
        }

        * {
          font-family: "Inter", system-ui, sans-serif;
          color: var(--text-gray);
          font-size: 1rem;
          margin: 0;
          padding: 0;
          outline: none;
          box-sizing: border-box;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        body {
          background-color: var(--background-light);
        }

        ::selection {
          background-color: var(--background-dark);
          color: white;
        }

        a {
          cursor: pointer;
        }

        img {
            width: 100%;
        }
      `}
    />
  )
}
