import React from "react"
import { css } from "@emotion/core"

import DesktopNav from "./DesktopNav"
import MobileNav from "./MobileNav"
import GlobalStyles from "../../GlobalStyles"

import basics from "../../../cms/basics"

export default props => {
  const siteName = basics.site_name

  return (
    <div
      css={css`
        .mobile {
          display: none;
        }

        @media (hover: none) {
          .desktop {
            display: none;
          }

          .mobile {
            display: block;
          }
        }
      `}
    >
      <GlobalStyles />

      <div className="desktop">
        <DesktopNav
          pages={props.pages}
          siteName={siteName}
        />
      </div>
      <div className="mobile">
        <MobileNav
          pages={props.pages}
          siteName={siteName}
        />
      </div>
    </div>
  )
}