/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import React, { useState } from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import * as Icon from "react-feather"

import NavLink from "./NavLink"
import ChevronDown from "./ChevronDown"

export default props => {
  const [open, setOpen] = useState(false)

  const handlePress = () => {
    setOpen(!open)
  }

  const pages = props.pages
  const render = []
  for(const category in pages) {
    if(pages[category].length === 1) {
      const page = pages[category][0]

      render.push(
        <NavLink
          to={page.to}
          text={category}
          key={page.to}
          style={css`
            display: block;
            margin-bottom: 0.3rem;
          `}
        />
      )
    } else {
      render.push(
        <div
          key={category}
          css={css`
            margin-bottom: .3rem;
          `}
        >
          <p
            css={css`
              opacity: .8;
              display: inline-block;
            `}
          >
            {category}
          </p>
          <ChevronDown
            style={css`
              opacity: .8;
            `}
          />

          {pages[category].map(page => (
            <NavLink
              to={page.to}
              text={page.name}
              key={page.to}
              style={css`
                display: block;
                margin-left: 1rem;
                margin-bottom: 0.3rem;
              `}
            />
          ))}
        </div>
      )
    }
  }

  return (
    <nav
      css={css`
        background-color: var(--background-dark);
        padding-left: 1.5rem;

        display: grid;
        grid-template-columns: auto 1fr;

        * {
          color: white;
          text-decoration: none;
          font-weight: 500;
        }
      `}
    >
      <div
        css={css`
          padding-top: 1.5rem;
        `}
      >
        <Link
          to="/"
          css={css`
            opacity: 1 !important;
          `}
        >
          <h1
            css={css`
              font-weight: 700 !important;
            `}
          >{props.siteName}</h1>
        </Link>

        <div
          css={css`
            margin-top: .7rem;
            margin-bottom: 1.5rem;

            display: ${open ? "block" : "none"};
          `}
        >
          {render}
        </div>
      </div>

      <div
        css={css`
          cursor: pointer;
          -webkit-tap-highlight-color: transparent;
          justify-self: end;
          padding: 1.5rem;

          svg {
            height: 1.2rem;
            width: auto;
          }

          * {
            stroke-width: 3px;
          }
        `}
        onClick={handlePress}
      >
        <div className="flex items-center">
            <p
              css={css`
                vertical-align: 22%;
                font-size: .9rem;
                opacity: .8;
                text-transform: uppercase;
                margin-right: .5rem;
              `}
            >
              Menu
            </p>
            {open ? <Icon.X /> : <Icon.Menu />}
        </div>
      </div>
    </nav>
  )
}
