/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

export default props => {
  const [isCurrent, setIsCurrent] = useState(false)

  useEffect(() => {
    if(window.location.pathname === props.to) {
      setIsCurrent(true)
    }
  }, [])

  return (
    <Link
      to={props.to}
      css={css`
        ${isCurrent ? "font-weight: 700 !important;" : ""}
        ${isCurrent ? "opacity: 1 !important;" : ""}
        ${props.dark ? "color: var(--text-black);" : ""}

        ${props.style}
        text-align: left;
      `}
      dangerouslySetInnerHTML={{ __html: props.text.replace(/ /g, '&nbsp;') }}
    />
  )
}
