import React from 'react'

export default function Img({ src, alt, width = 1, ...rest }) {
    return (
        <img
            src={(src.startsWith('/') ? '' : '/') + src}
            alt={alt}
            {...rest}
        />
    )
}
