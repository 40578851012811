import React from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"

import Nav from "./Nav/index.js"
import Img from '../Img'

import basics from "../../cms/basics"

export default props => {
  return (
    <React.Fragment>
      <div>
        <header
          css={css`
            background-color: white;
          `}
        >
          {/* box with logo above nav */}
          <Link
            to="/"
            css={css`
              text-decoration: none;
            `}
          >
            <div
              css={css`
                display: grid;
                grid-template-columns: max-content 120px;
                grid-column-gap: 2rem;
                padding: 1rem;

                /* centers both items */
                max-width: max-content;
                margin: 0 auto;

                div {
                  align-self: center;
                }

                span {
                  font-family: "Jaldi", sans-serif;
                  color: var(--text-black);
                  font-size: 3rem;
                  font-weight: 700;
                  display: block;
                }

                span.first {
                  margin-bottom: -1.7rem;
                }

                @media (hover: none) {
                  grid-template-columns: 90px;

                  .text {
                    display: none;
                  }
                }
              `}
            >
              <div className="text">
                <span className="first">One Cello,</span>
                <span>One Planet</span>
              </div>

              <div className="image">
                <Img
                    src={basics.logo}
                    alt="Logo of cellist playing a cello that is colored green and blue like the Earth."
                    css={css`height: 120px; height: auto`}
                    width={120}
                />
              </div>
            </div>
          </Link>

          <Nav
            pages={{
              "Home": [
                {
                  "to": "/"
                }
              ],
              "Meet the Artist": [
                {
                  "to": "/meet-the-artist/"
                }
              ],
              "Performances": [
                {
                  "name": "Calendar",
                  "to": "/performances/"
                },
                {
                  "name": "Funds Raised",
                  "to": "/funds-raised/"
                },
                {
                  "name": "Recordings",
                  "to": "/recordings/"
                },
                {
                  "name": "Testimonials",
                  "to": "/testimonials/"
                }
              ],
              "Sponsor": [
                {
                  "name": "Sponsor a Concert",
                  "to": "/sponsor/"
                },
                {
                  "name": "Publicity Materials",
                  "to": "/publicity-materials/"
                }
              ],
              "Resources": [
                {
                    "name": "Articles",
                    "to": "/articles/"
                },
                {
                    "name": "What We Can Do",
                    "to": "/what-we-can-do/"
                },
                {
                    "name": "Organizations",
                    "to": "/organizations/"
                }
              ],
              "Donate": [
                {
                  "to": "/donate/"
                }
              ],
              "Contact": [
                {
                  "to": "/contact/"
                }
              ],
              "Acknowledgements": [
                {
                  "to": "/acknowledgements/"
                }
              ]
            }}
          />
        </header>

        <main
          css={css`
            padding: 2rem;
            max-width: 64rem;
            margin: 0 auto;
            padding-bottom: 8rem;

            @media (max-width: 900px) {
              padding: 1.5rem;
              padding-bottom: 6rem;
            }
          `}
        >
          {props.children}
        </main>

      </div>
    </React.Fragment>
  )
}
